<script setup lang="ts">
import type { MenuModel } from './types/app'

useHead({
  titleTemplate: titleChunk => titleChunk || 'Blog Yescapa',
})

const { menuLinks } = useMenuLinks()
const { socialLinks } = useSocialLinks()

await callOnce(async () => {
  const fetchSocialLinks = async () => {
    const { id, about, site, ...socials } = await useApi().routing.getSocials()
    socialLinks.value = socials
  }

  const fetchMenuLinks = async () => {
    const { items } = await useApi().routing.getMenus()

    const menus = items.reduce((acc: Record<string, MenuModel[]>, cur) => {
      acc[cur.slug] = cur.menu_items
        .filter(({ computed_url }) => computed_url)
        .map(({ computed_url, computed_title, sub_menu }) => {
          return {
            computed_url,
            computed_title,
            sub_menu: sub_menu
              .filter(({ computed_url }) => computed_url)
              .map(({ computed_url, computed_title }) => ({ computed_url, computed_title })),
          }
        })

      return acc
    }, {})

    menuLinks.value = menus
  }

  await Promise.all([
    fetchSocialLinks(),
    fetchMenuLinks(),
  ])
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 250ms;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
