import { YscApiConsumer } from '@yescapa-dev/ysc-api-js/modern'
import type { $Fetch } from 'ofetch'

export default defineNuxtPlugin({
  name: 'ysc:api',
  dependsOn: ['ysc:sentry', 'i18n:plugin'],
  parallel: true,
  setup() {
    const { $i18n } = useNuxtApp()
    const { public: { api: { rental: { url, key } } } } = useRuntimeConfig()

    const modernInstance = $fetch.create({
      onRequest({ options }) {
        options.headers = {
          ...options.headers,
          'X-API-KEY': key,
          'Accept-Language': $i18n.locale.value,
        }
      },
    })

    const apiConsumer = new YscApiConsumer(modernInstance as $Fetch, url)

    return {
      provide: {
        apiConsumer,
      },
    }
  },
})
