<script setup lang="ts">
const { menuLinks } = useMenuLinks()
const { socialLinks } = useSocialLinks()

const links = computed(() => {
  return Object.entries(socialLinks.value).map(([key, link]) => {
    let icon: ReturnType<typeof resolveComponent> | undefined = undefined
    switch (key) {
      case 'facebook': {
        icon = resolveComponent('LazyYscIconsFacebook')
        break
      }
      case 'instagram': {
        icon = resolveComponent('LazyYscIconsInstagram')
        break
      }
      case 'pinterest': {
        icon = resolveComponent('LazyYscIconsPinterest')
        break
      }
      case 'twitter': {
        icon = resolveComponent('LazyYscIconsX')
        break
      }
      case 'youtube': {
        icon = resolveComponent('LazyYscIconsYoutube')
        break
      }
    }

    return {
      key,
      link,
      icon,
    }
  })
})
</script>

<template>
  <footer>
    <div class="bg-gray-100">
      <div class="container space-y-10 py-14 lg:space-y-0">
        <div class="flex flex-col gap-10 lg:flex-row">
          <div class="space-y-4 lg:w-5/12">
            <YscLogo />

            <p>
              {{ $t('footer.about_section.description') }}
            </p>

            <ul
              v-if="links.length"
              class="mt-6 flex space-x-5"
            >
              <li
                v-for="({ link, key, icon }) in links"
                :key="`socials_${key}`"
              >
                <NuxtLink
                  v-if="link"
                  :to="link"
                  :title="key"
                  class="block rounded-full bg-gray-200 p-2 text-gray-500 hover:text-black focus-visible:z-10 focus-visible:ring-2 focus-visible:ring-blue-500"
                >
                  <span class="sr-only">
                    {{ key }}
                  </span>
                  <component
                    :is="icon"
                    class="h-5 w-5"
                  />
                </NuxtLink>
              </li>
            </ul>
          </div>

          <div class="space-y-4 lg:w-3/12">
            <p class="text-xl font-semibold">
              {{ $t('footer.services_section.title') }}
            </p>

            <ul class="space-y-1.5">
              <li
                v-for="(menu_item, index) in menuLinks['footer-main']"
                :key="`footer-main_mi_${index}`"
              >
                <NuxtLink
                  :to="menu_item.computed_url"
                  class="rounded hover:underline focus-visible:z-10 focus-visible:ring-2 focus-visible:ring-blue-500"
                >
                  {{ menu_item.computed_title }}
                </NuxtLink>
              </li>
            </ul>
          </div>

          <div class="lg:w-4/12">
            <div class="space-y-4">
              <p class="text-xl font-semibold">
                {{ $t('footer.newsletter_section.title') }}
              </p>

              <p>
                {{ $t('footer.newsletter_section.description') }}
              </p>

              <AppNewsletterInput />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-50">
      <div class="container py-6">
        <p>&copy;{{ new Date().getFullYear() }} Yescapa</p>
      </div>
    </div>
  </footer>
</template>
